<template>
  <v-container class="container" fluid>
    <v-card flat class="mb-3">
      <v-btn
        small
        class="elevation-4"
        color="primary"
        v-on:click="add_click()"
        >{{ $t("add_button") }}</v-btn
      >
    </v-card>
    <!-- filter panel -->
    <GsDataFilter
      ref="dataFilter"
      :appSetting="appSettingKey"
      :filters="filters"
      v-on:changed="onFilterChanged"
    />
    <!-- table -->
    <GsDataTable
      ref="table"
      api="idp_api_url"
      endpoint="/scopes"
      :appSetting="appSettingKey"
      :headers="headers"
      :default-sort-by="['name']"
      :default-sort-desc="[false]"
      :beforeCallApi="beforeCallApi"
      :afterCallApi="afterCallApi"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="scopeId"
      show-expand
      @click:row="rowClicked"
      @after-refresh="afterRefresh"
    >
      <template v-slot:item.createdAt="{ item }">
        <span
          >{{ item.createdAt | getDisplayDate }} ({{
            item.createdBy.name | empty
          }})</span
        >
      </template>
      <template v-slot:item.updatedAt="{ item }">
        <span v-if="item.updatedAt"
          >{{ item.updatedAt | getDisplayDate }} ({{
            item.updatedBy.name | empty
          }})</span
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <GsActionsMenu
          :actions="actions"
          :onActivate="actionId => action_activate(actionId, item)"
        />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <GsScopeDetails :scope="item" />
        </td>
      </template>
    </GsDataTable>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsScopeDetails from "@/components/GsScopeDetails";
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";

export default {
  name: "Scopes",
  components: {
    GsScopeDetails,
    GsDataTable,
    GsActionsMenu,
    GsDataFilter
  },
  props: {},
  data() {
    return {
      appSettingKey: "scopes",
      items: [],
      expanded: [],
      singleExpand: true,
      filter: {
        scopeName: null
      },
      initialized: false,
      actions: ["edit"],
      // status: 0,
      // statusValues: {
      //   browse: 0,
      //   add: 1,
      //   edit: 2
      // },
      filters: [
        {
          field: "scopeName",
          component: "GsTextFilter",
          params: {}
        },
        {
          field: "scopeId",
          component: "GsIdFilter",
          params: {}
        }
      ]
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("actions"),
          value: "actions",
          width: 100,
          sortable: false
        },
        {
          text: this.$t("name"),
          align: "left",
          value: "scopeName",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("description"),
          align: "left",
          value: "description",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("updatedAt"),
          value: "updatedAt",
          width: 150,
          sortable: true
        },
        { text: "", value: "data-table-expand" }
      ];
    }
  },
  mounted() {
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      try {
        // console.log("refreshTable");
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      params.url += this.$refs.dataFilter.getFilter();
      params.url += "&r8sFields=createdBy.name,updatedBy.name";
      return params;
    },
    async afterCallApi(params) {
      return params;
    },
    afterRefresh({ items }) {
      const expandedId = this.loadExpanded();
      if (expandedId) {
        const expandItem = items.find(p => p.scopeId == expandedId);
        if (expandItem) {
          this.expanded = [expandItem];
          this.identity = expandItem;
        }
      }
    },
    onFilterChanged() {
      this.$refs.table.goToFirstPage();
    },
    async getScope(scopeId) {
      const result = await callAPI({
        url: `${this.$store.state.idp_api_url}/scopes/${scopeId}`,
        method: "GET"
      });
      return result.data;
    },
    async add_click() {
      // this.status = this.statusValues.add;
      // this.expanded = [];
      // this.$nextTick(function () {
      //   // const initData = {
      //   //   scopeName: "test",
      //   //   description: "desc",
      //   //   client: [],
      //   //   resourceServer: []
      //   // };
      //   const initData = {
      //     scopeName: null,
      //     description: null,
      //     client: [],
      //     resourceServer: []
      //   };
      //   this.$refs.editor.add(initData);
      // });
      this.$router.push_safe(`/scopes/new`);
    },
    async edit_click(item) {
      // this.status = this.statusValues.edit;
      // this.expanded = [];
      // this.$nextTick(async function () {
      //   let initData = await this.getScope(item.scopeId);
      //   if (typeof initData.client === "undefined") initData.client = [];
      //   if (typeof initData.resourceServer === "undefined")
      //     initData.resourceServer = [];
      //   this.$refs.editor.edit(initData);
      // });
      this.expand(item);
      this.$router.push_safe(`/scopes/${item.scopeId}`);
    },
    // async cancel_click() {
    //   this.status = this.statusValues.browse;
    // },
    // async save_click(sendData) {
    //   // console.log("sendData", sendData)
    //   let url = `${this.$store.state.idp_api_url}/scopes`;
    //   let method = "POST";
    //   if (sendData.scopeId) {
    //     url += `/${sendData.scopeId}`;
    //     method = "PATCH";
    //   }
    //   delete sendData.scopeId;
    //   delete sendData.createdBy;
    //   delete sendData.createdAt;
    //   delete sendData.updatedBy;
    //   delete sendData.updatedAt;
    //   delete sendData._uri;

    //   if (sendData.description == null || sendData.description == "") {
    //     delete sendData.description;
    //   }

    //   const data = sendData;
    //   try {
    //     await callAPI({ url, method, data });
    //   } catch (err) {
    //     console.log(err);
    //     this.validationResult = err;
    //   }
    //   this.status = this.statusValues.browse;
    //   await this.refreshTable();
    // },
    async delete_click(item) {
      alert("Not implemented!");
    },
    async action_activate(actionId, item) {
      switch (actionId) {
        case "edit":
          this.edit_click(item);
      }
    },
    rowClicked(item) {
      const earlyReturn = this.expanded.some(p => p.scopeId === item.scopeId);
      if (earlyReturn) {
        this.expanded = [];
        this.saveExpanded(null);
        return;
      }
      this.expand(item);
    },
    expand(item) {
      if (!this.$refs.table) return;
      if (item) {
        this.expanded = [item];
        this.saveExpanded(item.scopeId);
        this.identity = item;
      }
    }
  }
};
</script>

<style lang="scss"></style>
