<template>
  <v-container class="pa-0 ma-0" fluid>
    <div class="d-flex" v-if="scope && !loading">
      <div style="width: 50%" tile>
        <v-card-title>Scope:</v-card-title>
        <v-list-item>
          <v-list-item-content>
            <!-- <v-list-item-title>{{ scope.scopeName }}</v-list-item-title>
            <v-list-item-subtitle>Id: {{ scope.scopeId }}</v-list-item-subtitle> -->

            <v-container fluid>
              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("id") }}:</b>
                </v-col>
                <v-col>{{ scope.scopeId }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("name") }}:</b>
                </v-col>
                <v-col>{{ scope.scopeName }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("description") }}:</b>
                </v-col>
                <v-col>{{ scope.description }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("_uri") }}:</b>
                </v-col>
                <v-col>{{ scope._uri }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("createdAt") }}:</b>
                </v-col>
                <v-col>{{ scope.createdAt | getDisplayDate }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("createdBy") }}:</b>
                </v-col>
                <v-col>{{ scope.createdBy.name | empty }}</v-col>
              </v-row>

              <v-row v-if="scope.updatedAt">
                <v-col class="col-sm-3">
                  <b>{{ $t("updatedAt") }}:</b>
                </v-col>
                <v-col>{{ scope.updatedAt | getDisplayDate }}</v-col>
              </v-row>

              <v-row v-if="scope.updatedAt">
                <v-col class="col-sm-3">
                  <b>{{ $t("updatedBy") }}:</b>
                </v-col>
                <v-col>{{ scope.updatedBy.name | empty }}</v-col>
              </v-row>
            </v-container>

            <!-- {{ scope }} -->
          </v-list-item-content>
        </v-list-item>
      </div>
      <div style="width: 33%" tile>
        <!-- <span>Clients:</span> -->
        <v-card-title>Clients:</v-card-title>
        <v-list-item two-line v-for="item in clients" :key="item.clientId">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.client_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="caption font-weight-bold">Code: </span>
              <span class="caption">{{ item.appCode }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span class="caption font-weight-bold">Type: </span>
              <span class="caption">{{ item.client_type }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span class="caption font-weight-bold">{{ $t("_uri") }}: </span>
              <span class="caption text-wrap">{{ item._uri }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div style="width: 33%" tile>
        <!-- <span>ResourceServers:</span> -->
        <v-card-title>ResourceServers:</v-card-title>
        <v-list-item
          two-line
          v-for="item in resourceServers"
          :key="item.resourceServerId"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.resourceName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="caption font-weight-bold">Code: </span>
              <span class="caption">{{ item.resourceCode }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span class="caption font-weight-bold">Origin: </span>
              <span class="caption">{{ item.resourceOrigin }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span class="caption font-weight-bold">{{ $t("_uri") }}: </span>
              <span class="caption text-wrap">{{ item._uri }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>
    <div v-if="!scope && !loading">
      <span>{{ $t("no_data") }}</span>
    </div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        class="ma-4"
      ></v-progress-circular>
      <span>{{ $t("loading") }}</span>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "GsScopeDetails",
  components: {},
  props: {
    scope: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      clients: [],
      resourceServers: [],
      loading: true
    };
  },
  created: async function() {
    this.getClients(this.scope);
    this.getResourceServers(this.scope);
  },
  watch: {
    scope(value) {
      this.getClients(value);
      this.getResourceServers(value);
    }
  },
  methods: {
    async getClients(scope) {
      this.loading = true;
      this.clients = [];
      if (!scope?.client?.length) {
        this.loading = false;
        return;
      }
      let clients = await this.getClient(scope.client);
      clients = clients.filter(p => p != null);
      this.clients = clients;
      this.loading = false;
    },
    async getResourceServers(scope) {
      this.loading = true;
      this.resourceServers = [];
      if (!scope?.resourceServer?.length) {
        this.loading = false;
        return;
      }
      let resourceServers = await this.getResourceServer(scope.resourceServer);
      resourceServers = resourceServers.filter(p => p != null);
      this.resourceServers = resourceServers;
      this.loading = false;
    }
  }
};
</script>
